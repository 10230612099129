import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const i18n = {
    supportedLngs: ['en', 'ko'],
    fallbackLng: 'en',
    defaultNS: 'common',
    react: { useSuspense: false },
};

export function useChangeLanguage(locale: string) {
    const { i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(locale);
    }, [locale, i18n]);
}

export const useLocale = () => {
    const { i18n } = useTranslation();
    return i18n.language;
};
